import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { get } from 'lodash'
import { rgba } from 'polished'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import useSize from '../../../hooks/useSize'

const MAILCHIMP_URL = 'https://xyz.us20.list-manage.com/subscribe/post' 
const MAILCHIMP_U = '50f452ffa3ea6f96b01462644'
const MAILCHIMP_ID = '5269aa7c25'

const signupSchema = Yup.object().shape({
 email: Yup.string().email('Invalid email').required('Required')
})

const popSwirl = keyframes`
  0% {
    transform: scale(0) rotate(360deg);
  }
  60% {
    transform: scale(0.8) rotate(-10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${({ theme }) => rgba(get(theme, 'popupBackground', '#fff'), 0.95)};
  z-index: 9999;
`

const FormCard = styled.div`
  padding: 20px;
  background: ${({ theme }) => get(theme, 'white', '#fff')};
  border-radius: 15px;
  box-shadow: 0 2px 5px 1px rgba(64,60,67,.66);
  animation: 0.4s ${popSwirl} ease-in-out;
`

const Title = styled.p`
  font-family: 'Montserrat';
  font-size: 27px;
  line-height: 30px;
  color: ${({ theme }) => get(theme, 'titleColor')};
  text-decoration: none;
  font-weight: 700;
  letter-spacing: -0.6912px;
`

const Subtitle = styled.p`
  font-size: 16px;
  margin: 24px 0;

  & > strong {
    font-weight: 600;
    color: ${({ theme }) => get(theme, 'titleColor')};
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  font-size: 16px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${({ theme, error }) => get(theme, error ? 'errorColor' : 'textColor')};
  border-bottom: 2px solid ${({ theme, error }) => get(theme, error ? 'errorColor' : 'textColor')};

  &:focus {
    outline: none;
    border: none;
    color: ${({ theme }) => get(theme, 'titleColor')};
    border-bottom: 2px solid ${({ theme }) => get(theme, 'titleColor')};
  }
`

const Button = styled.button`
  cursor: pointer;
  background: ${({ theme }) => get(theme, 'white')};
  color: ${({ theme }) => get(theme, 'textColor')};
  border: 2px solid ${({ theme }) => get(theme, 'textColor')};
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 24px;
  padding: 8px 16px;

  &:hover {
    color: ${({ theme }) => get(theme, 'titleColor')};
    border: 2px solid ${({ theme }) => get(theme, 'titleColor')};
  }
`

const CustomForm = ({ onValidated, updateHasBeenDisplayed, updateShouldDisplay }) => {
  const node = useRef()

  const closePopup = useCallback((evt) => {
    if (node.current.contains(get(evt, 'target'))) {
      return
    }

    updateHasBeenDisplayed(true)
    updateShouldDisplay(false)
  }, [updateShouldDisplay, updateHasBeenDisplayed])

  const onSubmit = useCallback(async (values, { resetForm }) => {
    try {
      await onValidated({
        EMAIL: get(values, 'email')
      })

      resetForm()

      localStorage.setItem('mailchimp_asked', true)
    } catch (err) {
      console.log(err)
    }

    return closePopup()
  }, [closePopup, onValidated])

  const { values, handleSubmit, handleBlur, handleChange, touched, errors } = useFormik({
    onSubmit,
    initialValues: {
      email: ''
    },
    validationSchema: signupSchema
  })

  return (
    <Container onClick={closePopup}>
      <FormCard ref={node}>
        <Title>Join our list</Title>
        <Subtitle>Receive my <strong>5 rules</strong> to get to financial freedom</Subtitle>
        <Form onSubmit={handleSubmit}>
          <Input
            name='email'
            error={!!get(touched, 'email') && !!get(errors, 'email')}
            value={get(values, 'email')}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder='Type your email' />
          <Button type='submit'>
            Download
          </Button>
        </Form>
      </FormCard>
    </Container>
  )
}

const MailChimpRegister = () => {
  const { pageHeight } = useSize()
  const [hasBeenAsked, updateHasBeenAsked] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shouldDisplay, updateShouldDisplay] = useState(false)
  const [hasBeenDisplayed, updateHasBeenDisplayed] = useState(false)

  const postUrl = `${MAILCHIMP_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`

  const handleScroll = () => setScrollPosition(window.pageYOffset)

  useEffect(() => {
    updateHasBeenAsked(localStorage.getItem('mailchimp_asked'))
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > pageHeight / 2 && !shouldDisplay) {
      return updateShouldDisplay(true)
    }
  }, [scrollPosition, pageHeight, shouldDisplay])

  if (!shouldDisplay || hasBeenDisplayed || !!hasBeenAsked) {
    return null
  }

  return (
    <MailchimpSubscribe
      url={postUrl}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status} 
          message={message}
          onValidated={formData => subscribe(formData)}
          updateShouldDisplay={updateShouldDisplay}
          updateHasBeenDisplayed={updateHasBeenDisplayed} />
      )} />
  )
}



export default MailChimpRegister
