import { useState, useEffect } from 'react'
import { reduce, max, min } from 'lodash'

const useSize = () => {
  const [data, updateData] = useState({})
  const [contentWidth, updateContentWidth] = useState(null)

  useEffect(() => {
    const calculated = reduce([...document.body.children], (acc, el) =>
      max([acc, el.getBoundingClientRect().right]), 0) 
      
    updateContentWidth(calculated - document.body.getBoundingClientRect().x)
  }, [])

  useEffect(() => {
    updateData({
      windowWidth:  document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
      pageWidth:    min([document.body.scrollWidth, contentWidth]),
      pageHeight:   document.body.scrollHeight,
      screenWidth:  window.screen.width,
      screenHeight: window.screen.height,
      pageX:        document.body.getBoundingClientRect().x,
      pageY:        document.body.getBoundingClientRect().y,
      screenX:     -window.screenX,
      screenY:     -window.screenY - (window.outerHeight-window.innerHeight),
    })
  }, [contentWidth])

  return data
}

export default useSize
