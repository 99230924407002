import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { GatsbySeo, ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { get, filter, isEqual, first } from 'lodash'

import Bio from '../../organisms/Bio'
import MainContainer from '../../atoms/MainContainer'
import MailChimpRegister from '../../organisms/MailchimpRegister'

const BlogHeader = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`

const ImageBlog = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`

const StyledHeader = styled.header`
  position: absolute;
`

const StyledBreadcrumb = styled.div`
  margin-bottom: 24px;

  * > li {
    float: left;
  }
`

const Title = styled.h1`
  font-family: 'Montserrat';
  font-weight: 900;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -1px;
  margin-bottom: 16px;
  color: ${({ theme }) => get(theme, 'white')};
`

const Date = styled.p`
  font-family: 'Montserrat';
  font-size: 19px;
  line-height: 32px;
  margin-bottom: 32px;
  color: ${({ theme }) => get(theme, 'white')};
`

const Body = styled.section`
  font-family: 'Merriweather';
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => get(theme, 'textColor')};

  & > p {
    margin-bottom: 32px;
    line-height: 26px;

    & > strong {
      font-weight: 700;
    }

    & > em {
      font-style: italic;
    }

    & > a {
      color: ${({ theme }) => get(theme, 'titleColor')};
    }
  }

  & > blockquote {
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: ${({ theme }) => get(theme, 'titleColor')};
    font-size: 19px;
    font-style: italic;
    line-height: 29px;
    padding-left: 24px;
    margin-top: 19px;
    margin-left: -24px;
    margin-right: 32px;
    margin-bottom: 32px;
    color: ${({ theme }) => get(theme, 'quoteColor')};
  }

  & > ul {
    list-style-position: outside;
    list-style-type: disc;
    margin-top: 16px;
    margin-bottom: 32px;

    & > li {
      margin-bottom: 16px;
      
      & > ul {
        margin-left: 32px;
        margin-top: 16px;
        list-style-position: outside;
        list-style-type: disc;
      }
    }
  }

  & > ol {
    list-style-position: outside;
    list-style-type: decimal;
    margin-top: 16px;
    margin-bottom: 32px;

    & > li {
      margin-bottom: 16px;
    }
  }

  & > h2 {
    font-family: 'Montserrat';
    font-size: 33px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 36.5px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  & > h3 {
    font-family: 'Montserrat';
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.7px;
    line-height: 30px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  & > h4 {
    font-family: 'Montserrat';
    font-size: 23px;
    font-weight: 700;
    letter-spacing: -0.6px;
    line-height: 25px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  & > h5 {
    font-family: 'Montserrat';
    font-size: 19px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 21px;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  & > h6 {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 18px;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`

const Nav = styled.nav`
  & > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
`

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteUrl = data.site.siteMetadata?.siteUrl || 'Url'
  const siteTitle = data.site.siteMetadata?.title || 'Title' 
  const siteAuthor = data.site.siteMetadata?.author.name || 'Jane Blogs'
  const { previous, next } = data

  const image = getImage(first(filter(data.images.nodes,
    ({ base }) => isEqual(base, get(post, 'frontmatter.image')))))

  return (
    <>
      <GatsbySeo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        openGraph={{
          url: `${siteUrl}/${post.fields.slug}`,
          title: post.frontmatter.title,
          description: post.frontmatter.description || post.excerpt,
          images: [
            {
              url: 'https://www.example.ie/og-image-01.jpg',
              width: 800,
              height: 600,
              alt: 'Og Image Alt',
            },
            {
              url: 'https://www.example.ie/og-image-02.jpg',
              width: 900,
              height: 800,
              alt: 'Og Image Alt Second',
            },
            { url: 'https://www.example.ie/og-image-03.jpg' },
            { url: 'https://www.example.ie/og-image-04.jpg' },
          ],
          site_name: siteTitle,
        }} />
      <ArticleJsonLd
        url={`${siteUrl}/${post.fields.slug}`}
        headline={post.frontmatter.title}
        images={[
          'https://example.com/photos/1x1/photo.jpg',
          'https://example.com/photos/4x3/photo.jpg',
          'https://example.com/photos/16x9/photo.jpg',
        ]}
        datePublished={post.frontmatter.date}
        dateModified={post.frontmatter.date}
        authorName={siteAuthor}
        publisherName={siteAuthor}
        publisherLogo='https://www.example.com/photos/logo.jpg'
        description={post.frontmatter.description || post.excerpt}
        overrides={{
          '@type': 'BlogPosting', // set's this as a blog post.
        }} />
      <MailChimpRegister />
      <article
        className='blog-post'
        itemScope
        itemType='http://schema.org/Article'>
        <BlogHeader>
          <ImageBlog image={image} alt={get(post, 'frontmatter.title')} />
          <StyledHeader>
            <Title itemProp='headline'>{post.frontmatter.title}</Title>
            <Date>{post.frontmatter.formattedDate}</Date>
          </StyledHeader>
        </BlogHeader>
        <MainContainer>
          <StyledBreadcrumb>
            <Breadcrumb location={location} crumbLabel={get(post, 'frontmatter.title')} />
          </StyledBreadcrumb>
          <Body
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp='articleBody' />
          <hr />
          <footer>
            <Bio />
          </footer>
        </MainContainer>
      </article>
      <MainContainer>
        <Nav>
          <ul>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel='prev'>
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel='next'>
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </Nav>
      </MainContainer>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        formattedDate: date(formatString: "MMMM DD, YYYY")
        description
        image
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    images: allFile(filter: {relativeDirectory: {eq: "articles"}, extension: {in: ["jpeg","png","jpg"]}}) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
